/* sarabun-100 - latin_thai */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 100;
  src: url("assets/fonts/sarabun/sarabun-v15-latin_thai-100.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* sarabun-100italic - latin_thai */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Sarabun";
  font-style: italic;
  font-weight: 100;
  src: url("assets/fonts/sarabun/sarabun-v15-latin_thai-100italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* sarabun-200 - latin_thai */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 200;
  src: url("assets/fonts/sarabun/sarabun-v15-latin_thai-200.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* sarabun-200italic - latin_thai */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Sarabun";
  font-style: italic;
  font-weight: 200;
  src: url("assets/fonts/sarabun/sarabun-v15-latin_thai-200italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* sarabun-300 - latin_thai */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 300;
  src: url("assets/fonts/sarabun/sarabun-v15-latin_thai-300.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* sarabun-300italic - latin_thai */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Sarabun";
  font-style: italic;
  font-weight: 300;
  src: url("assets/fonts/sarabun/sarabun-v15-latin_thai-300italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* sarabun-regular - latin_thai */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  src: url("assets/fonts/sarabun/sarabun-v15-latin_thai-regular.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* sarabun-italic - latin_thai */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Sarabun";
  font-style: italic;
  font-weight: 400;
  src: url("assets/fonts/sarabun/sarabun-v15-latin_thai-italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* sarabun-500 - latin_thai */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  src: url("assets/fonts/sarabun/sarabun-v15-latin_thai-500.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* sarabun-500italic - latin_thai */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Sarabun";
  font-style: italic;
  font-weight: 500;
  src: url("assets/fonts/sarabun/sarabun-v15-latin_thai-500italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* sarabun-600 - latin_thai */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 600;
  src: url("assets/fonts/sarabun/sarabun-v15-latin_thai-600.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* sarabun-600italic - latin_thai */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Sarabun";
  font-style: italic;
  font-weight: 600;
  src: url("assets/fonts/sarabun/sarabun-v15-latin_thai-600italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* sarabun-700 - latin_thai */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 700;
  src: url("assets/fonts/sarabun/sarabun-v15-latin_thai-700.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* sarabun-700italic - latin_thai */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Sarabun";
  font-style: italic;
  font-weight: 700;
  src: url("assets/fonts/sarabun/sarabun-v15-latin_thai-700italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* sarabun-800 - latin_thai */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 800;
  src: url("assets/fonts/sarabun/sarabun-v15-latin_thai-800.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* sarabun-800italic - latin_thai */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Sarabun";
  font-style: italic;
  font-weight: 800;
  src: url("assets/fonts/sarabun/sarabun-v15-latin_thai-800italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
