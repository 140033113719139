/* kanit-100 - latin_thai */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 100;
    src: url('assets/fonts/kanit/kanit-v15-latin_thai-100.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* kanit-100italic - latin_thai */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Kanit';
    font-style: italic;
    font-weight: 100;
    src: url('assets/fonts/kanit/kanit-v15-latin_thai-100italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* kanit-200 - latin_thai */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 200;
    src: url('assets/fonts/kanit/kanit-v15-latin_thai-200.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* kanit-200italic - latin_thai */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Kanit';
    font-style: italic;
    font-weight: 200;
    src: url('assets/fonts/kanit/kanit-v15-latin_thai-200italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* kanit-300 - latin_thai */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 300;
    src: url('assets/fonts/kanit/kanit-v15-latin_thai-300.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* kanit-300italic - latin_thai */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Kanit';
    font-style: italic;
    font-weight: 300;
    src: url('assets/fonts/kanit/kanit-v15-latin_thai-300italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* kanit-regular - latin_thai */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    src: url('assets/fonts/kanit/kanit-v15-latin_thai-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* kanit-italic - latin_thai */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Kanit';
    font-style: italic;
    font-weight: 400;
    src: url('assets/fonts/kanit/kanit-v15-latin_thai-italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* kanit-500 - latin_thai */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 500;
    src: url('assets/fonts/kanit/kanit-v15-latin_thai-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* kanit-500italic - latin_thai */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Kanit';
    font-style: italic;
    font-weight: 500;
    src: url('assets/fonts/kanit/kanit-v15-latin_thai-500italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* kanit-600 - latin_thai */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 600;
    src: url('assets/fonts/kanit/kanit-v15-latin_thai-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* kanit-600italic - latin_thai */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Kanit';
    font-style: italic;
    font-weight: 600;
    src: url('assets/fonts/kanit/kanit-v15-latin_thai-600italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* kanit-700 - latin_thai */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 700;
    src: url('assets/fonts/kanit/kanit-v15-latin_thai-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* kanit-700italic - latin_thai */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Kanit';
    font-style: italic;
    font-weight: 700;
    src: url('assets/fonts/kanit/kanit-v15-latin_thai-700italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* kanit-800 - latin_thai */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 800;
    src: url('assets/fonts/kanit/kanit-v15-latin_thai-800.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* kanit-800italic - latin_thai */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Kanit';
    font-style: italic;
    font-weight: 800;
    src: url('assets/fonts/kanit/kanit-v15-latin_thai-800italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* kanit-900 - latin_thai */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 900;
    src: url('assets/fonts/kanit/kanit-v15-latin_thai-900.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* kanit-900italic - latin_thai */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Kanit';
    font-style: italic;
    font-weight: 900;
    src: url('assets/fonts/kanit/kanit-v15-latin_thai-900italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }